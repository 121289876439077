html {
  background-color: black;
  color: white;
}

#root {
  position: absolute;
  display: flex;

  width: 100%;
  height: 100%;

  justify-content: center;
  align-items: center;
}
